import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PythonConsole from "./components/python-console";
import Home from "./pages/home";
import  LandingPageT from "./pages/landing3";
import LandingPage from "./pages/landing";

import Contact from "./pages/contact";
import RedirectPage from "./pages/redirect";
import ChunkPage from "./pages/chunk-page";
import FigureCard from "./components/figure-card";
import JavaScriptConsole from "./components/js-console";

const RoutesCode = () => (
  <Router>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/teachers" element={<LandingPageT />} />

      <Route path="/playground/" element={<PythonConsole />} />
      <Route path="/playground/:filename" element={<PythonConsole />} />
      <Route path="/playground/js/" element={<JavaScriptConsole />} />

      <Route path="/figures/" element={<FigureCard />} />
      <Route path="/figures/:filename" element={<FigureCard />} />

      <Route exact path="/redirect" element={<RedirectPage />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/about" element={<ChunkPage chunkKey="about_lcoders" title="Acerca de littlecoders.dev" />} />
      <Route exact path="/glossary" element={<ChunkPage chunkKey="glossary_lcoders" title="Glosario de littlecoders.dev" />} />
      <Route exact path="/cheat-sheet" element={<ChunkPage chunkKey="cheatsheet_lcoders" title="Cheatsheet de littlecoders.dev" />} />
      <Route path="*" element={<Home message={"No encontrado"} />} />
    </Routes>
  </Router>
);

export default RoutesCode;
