import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer
            className="p-2 text-white"
            style={{ fontSize: "2rem", backgroundColor: "#5B88FA", fontWeight: "bold" }}
        >
            <div className="footer mt-4 text-center text-white" style={{ fontSize: "0.9rem", color: "#777" }}>
                <p >
                    <Link to="/contact" style={{ color: "white" }} className="me-3">Contacto</Link>
                    <Link to="/about" style={{ color: "white" }}>Acerca de</Link>
                </p>
                <p>© 2024-2025 littlecoders.dev - Aprende Python mientras organizas tu día</p>
            </div>
        </footer>
    );
}

export default Footer;
