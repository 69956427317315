// src/LandingPage.js
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom'
import { Button, Popup, NavBar } from "antd-mobile";
import { Modal } from "antd";
import Header from "../components/visual/header";
import Footer from "../components/visual/footer";
import PDFViewer from "../components/visual/pdf-viewer-4";
import { FE_BASE_URI } from "../constants/const";

import agenda from "../assets/portada-agenda-1.png";
import logo from "../assets/logo.webp";
import agenda3 from "../assets/library_bg.jpg";

const LandingPage = () => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false); //Desktop
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // movil

  useEffect(() => {
    document.title = "littlecoders.dev. Aprende Python mientras organizas tu día";
  }, []);

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Header />
      <div className="home-banner"
        style={{
          width: "100%", backgroundColor: "#5B88FA",
          backgroundImage: `url(${agenda3})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: "relative"
        }}>

      </div>

      <div
        className="container mb-4"
        style={{
          padding: "20px",
          backgroundColor: "#f8f9fa",
          color: "#333",
        }}
      >
        {/* Cabecera */}
        <div className="header text-center" style={{ marginBottom: "30px" }}>
          {/* <ImageCarousel /> */}
          <img
            src={logo}
            alt="littlecoders.dev Logo"
            style={{ width: "125px", marginBottom: "15px", display: "none" }}
          />
          <h1 style={{ fontSize: "2rem", color: "#5B88FA" }}>
            Agenda <span style={{ fontFamily: "Nunito", fontOpticalSizing: "auto", fontWeight: 900, fontStyle: "normal" }}>LITTLE<span style={{ color: "#FA824C" }}>CODERS.</span><span style={{ color: "#FA824C", fontSize: "1rem" }}>dev</span></span>
          </h1>
          <h2 style={{ fontSize: "1.25rem", color: "#555" }}>
            <i>
              Aprende Python mientras organizas tu día
            </i>
          </h2>
        </div>

        <div>
          <p style={{ fontSize: "1.1rem", color: "#666", lineHeight: "1.5" }}>
            ¿Tienes curiosidad por la programación?
            Hemos creado la Agenda Escolar Interactiva littlecoders.dev para tí, que combina la agenda en papel y esta web para probar código Python y que hará que te organices mejor mientras das tus primeros pasos en la programación.
          </p>
        </div>

        {/* Imagen destacada */}
        <div className="my-5 text-center banner-agenda" style={{ backgroundColor: "#FA824C" }}>
          <img
            src={agenda}
            alt="Agenda LittleCoders.dev"
            style={{ width: "100%", borderRadius: "15px", transform: "rotate(-22deg)" }}
          />
          <div className="d-md-none pt-3 mt-4">
            <h2>
              <a onClick={() => setIsDrawerOpen(true)}>
                <i class="fa-solid fa-magnifying-glass me-1"></i>Explora la Agenda littlecoders.dev
              </a>
            </h2>
          </div>
          <div className="d-none d-md-block mt-4">
            <h3>
              <a onClick={() => setModalVisible(true)}>
                <i class="fa-solid fa-magnifying-glass me-1"></i> Explora la Agenda littlecoders.dev
              </a>
            </h3>
          </div>
        </div>

        {/* Botón de compra */}
        <Button
          color="primary"
          size="large"
          className="mt-4 mx-2"
          style={{
            backgroundColor: "#5B88FA",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            fontSize: "1rem",
            width: "47%",
            display: "inline-block"
          }}
          onClick={() => {
            window.open("/redirect?url=https://www.amazon.es/dp/B0DNTNG591", "_blank");
          }}
        >
          <i className="fa-solid fa-calendar-days me-2"></i>
          <br />
          Compra en Amazon tu agenda littlecoders.dev 2024-2025
        </Button>

        <Button
          color="primary"
          size="large"
          className="mt-4 mx-2"
          style={{
            backgroundColor: "rgba(246, 9, 9, 0.8)",
            border: "none",
            padding: "10px 20px",
            borderRadius: "5px",
            fontSize: "1rem",
            width: "47%",
            display: "inline-block"

          }}
          onClick={() => {
            window.open("/redirect?url=https://www.amazon.es/dp/B0DR7BGF7L", "_blank");
          }}
        >
          ¡ NUEVO ! <br />
          <i className="fa-solid fa-calendar-days me-2"></i>Compra en Amazon tu agenda littlecoders.dev 2025-2026
        </Button>

        {/* Sección de bienvenida */}
        <div className="mt-5">
          <h2 className="text-center" style={{ color: "#5B88FA", marginBottom: "15px" }}>
            ¿Qué es littlecoders.dev?
          </h2>
          <p style={{ fontSize: "1.1rem", color: "#666", lineHeight: "1.5" }}>
            El lugar perfecto para que los estudiantes como tú comiencen su aventura en la
            programación. Aprende mientras te organizas y te diviertes.
          </p>
        </div>
        
        {/* Botón para comenzar a programar */}
        <div className="text-center">
        <Button
          color="secondary"
          size="large"
          className="mt-4"
          style={{
            backgroundColor: "#FA824C",
            border: "none",
            padding: "10px 20px",
            borderRadius: "25px",
            fontSize: "1rem",
          }}
          onClick={() => {
            navigate("/playground");
          }}
        >
          <i className="fas fa-code me-2"></i> ¡Empieza a Programar!
        </Button>
        </div>
        {/* Sección de ventajas */}
        <div className="mt-5">
          <h2 className="text-center" style={{ color: "#5B88FA", marginBottom: "15px" }}>
            ¿Por qué aprender con nosotros?
          </h2>
          <p style={{ fontSize: "1rem", color: "#666", lineHeight: "1.5" }}>
            littlecoders.dev ofrece una plataforma interactiva para aprender Python de
            forma divertida y práctica. Con ejercicios semanales, recursos útiles y
            herramientas diseñadas para estudiantes, estarás siempre un paso por adelante.
          </p>
        </div>
        <div className="mt-4">
          <h2 className="text-center" style={{ color: "#5B88FA", marginBottom: "15px" }}>
            ¿Eres profesor?
          </h2>
          <p style={{ fontSize: "1.1rem", color: "#666", lineHeight: "1.5" }}>
            Sabemos que la programación es una de esas asignaturas que asusta, pero tú tienes el poder de convertir el miedo en curiosidad.
            Mira las posibiilidades de esta agenda para tus alumnos en la sección de <Link to="/teachers">profesores</Link>
          </p>
        </div>

      </div>

      {/* Footer */}
      <Footer />

      <Modal open={modalVisible} onCancel={closeModal} footer={null}
        style={{
          top: 0,
          padding: 0,
          height: '99vh',
          maxWidth: '100vw',
        }}
        bodyStyle={{
          height: 'calc(100vh - 50px)',
          overflowY: 'auto',
        }}>
        <div>
          <PDFViewer url={FE_BASE_URI + "preview2425.pdf"} />
        </div>
      </Modal>

      <Popup
        visible={isDrawerOpen}
        getContainer={false} // para que funcione en mobile
        onMaskClick={() => {
          setIsDrawerOpen(false);
        }}
        bodyStyle={{ width: "100%" }}
        position="left"
      >
        <NavBar
          onBack={() => {
            setIsDrawerOpen(false);
          }}
          className="back-bar"
        >
          Agenda littlecoders.dev
        </NavBar>
        <div className="pt-1">
          <PDFViewer url={FE_BASE_URI + "preview2425.pdf"} />
        </div>
      </Popup>
    </>
  );

};

export default LandingPage;
